<template>
  <div class="setting-wrap">
    <div class="header">
      <h3>设置管控指标</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="content">
      <div class="header-title">
        <div class="info-title">
          <div>管控设置</div>
        </div>
        <div
          class="add-btn"
          @click="addDimension()"
        >
          <span>添加管控指标</span>
        </div>
      </div>
      <div class="content-info">
        <div v-if="dataList && dataList.length > 0">
          <div
            class="info-item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div class="name">
              <div>管控维度 <span>*</span></div>
              <div class="height36-input">
                <el-select
                  popper-class="w-block-select-down yellow-select-panel"
                  v-model="item.progressControlType"
                  @change="changeDimension($event, index)"
                  size='small'
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in dimensionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="selectIds.includes(item.value)"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="name mlr-24">
              <div>管控指标 <span>*</span></div>
              <div class="height36-input">
                <el-select
                  popper-class="w-block-select-down yellow-select-panel"
                  v-model="item.progressControlTarget"
                  size='small'
                  placeholder="请选择"
                  @change="changeIndex"
                >
                  <el-option
                    v-for="item in indexObj[item.progressControlType - 1]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div
              class="sub-btn"
              @click="removeItem(index)"
            >
              <span class="el-icon-minus"></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="brand-zero"
        v-if="dataList && dataList.length === 0"
      >
        <div>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂未设置管控指标</p>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        :class="[isDisabled ? 'sure' : 'isDisabled']"
        @click="sure()"
      >保存</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      isDisabled: true,
      dataList: [],
      dimensionId: '',
      indexId: '',
      dimensionList: [
        { label: '业态招商进度', value: 1 },
        { label: '楼层招商进度', value: 2 },
        { label: '主次力店招商进度', value: 3 },
        { label: '品牌级次招商进度', value: 4 },
        { label: '品牌跟进进度', value: 5 },
        { label: '项目招商进度', value: 6 }
      ],
      indexList: [],
      indexObj: [
        [
          { label: '签约面积完成率', value: 1 },
          { label: '签约铺位完成率', value: 2 },
          { label: '签约品牌数量', value: 3 },
          { label: '入驻率', value: 4 }
        ],
        [
          { label: '签约面积完成率', value: 1 },
          { label: '签约铺位完成率', value: 2 },
          { label: '开店率', value: 3 }
        ],
        [
          { label: '签约面积完成率', value: 1 },
          { label: '签约铺位完成率', value: 2 },
          { label: '开店率', value: 3 }
        ],
        [
          { label: '各级次品牌签约量', value: 1 }
        ],
        [
          { label: '各进度品牌跟进量', value: 1 }
        ],
        [
          { label: '签约面积完成率', value: 1 },
          { label: '签约铺位完成率', value: 2 },
          { label: '签约品牌数量', value: 3 },
          { label: '开店率', value: 4 }
        ]
      ],
      selectIds: []
    }
  },
  props: {
    projectId: {
      type: Number
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    changeDimension (val, idx) {
      // this.indexList = []
      // this.indexId = ''
      // this.indexList = this.indexObj[val - 1]
      this.selectIds = []
      this.dataList = this.dataList.map((item, index) => {
        if (index === idx) {
          item.progressControlTarget = ''
        }
        this.selectIds.push(item.progressControlType)
        return item
      })
      if (this.dataList.length > 0) {
        for (const item of this.dataList) {
          if (item.progressControlType === '' || item.progressControlTarget === '') {
            this.isDisabled = false
            return false
          } else {
            this.isDisabled = true
          }
        }
      }
    },
    changeIndex () {
      if (this.dataList.length > 0) {
        for (const item of this.dataList) {
          if (item.progressControlType === '' || item.progressControlTarget === '') {
            this.isDisabled = false
            return false
          } else {
            this.isDisabled = true
          }
        }
      }
    },
    getList () {
      this.axios.post(api.getProgressControlConfigure, { projectId: this.projectId })
        .then((res) => {
          this.dataList = res.data.data
          if (this.dataList.length > 0) {
            for (const item of this.dataList) {
              this.selectIds.push(item.progressControlType)
            }
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        })
    },
    addDimension () {
      if (this.dataList.length > 0) {
        if (this.dataList[this.dataList.length - 1].progressControlType === '' || this.dataList[this.dataList.length - 1].progressControlTarget === '') {
          this.$message({
            type: 'warning',
            message: '请完成当前指标管控设置，再添加新管控指标！'
          })
          return false
        }
      }
      if (this.dataList.length === 6) {
        this.$message({
          type: 'warning',
          message: '最多添加5个管控维度！'
        })
        return false
      } else {
        this.dataList.push({
          progressControlType: '',
          progressControlTarget: ''
        })
        for (const item of this.dataList) {
          if (item.progressControlType === '' || item.progressControlTarget === '') {
            this.isDisabled = false
            return false
          } else {
            this.isDisabled = true
          }
        }
      }
    },
    removeItem (index) {
      this.$confirm('删除该管控指标将清除该指标的节点设置，是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'invitePosition',
        showClose: false
      }).then((res) => {
        if (res === 'confirm') {
          this.dataList.splice(index, 1)
          this.selectIds.splice(index, 1)
          if (this.dataList.length > 0) {
            for (const item of this.dataList) {
              if (item.progressControlType === '' || item.progressControlTarget === '') {
                this.isDisabled = false
                return false
              } else {
                this.isDisabled = true
              }
            }
          }
        }
      })
    },
    sure () {
      if (!this.isDisabled) return
      let flag = true
      this.dataList = this.dataList.map(item => {
        if (item.progressControlTarget === '' || item.progressControlType === '') {
          flag = false
        }
        item.projectId = this.projectId
        return item
      })
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '请选择对应的管控维度或者管控指标！'
        })
        return
      }
      const array = this.dataList
      if (this.dataList.length === 0) {
        const obj = {}
        obj.progressControlType = 0
        obj.progressControlTarget = 0
        obj.projectId = this.projectId
        array.push(obj)
      }
      this.axios.post(api.saveProgressControlConfigure, array)
        .then((res) => {
          this.$message({
            type: 'success',
            message: '保存成功!'
          })
          this.dataList = []
          this.getList()
          this.$emit('editSuccess')
        })
    },
    close () {
      this.$emit('closeSetting')
    }
  }
}
</script>

<style lang="stylus" scoped>
.header-title
  display flex
  margin-bottom 16px
  .info-title
    position relative
    font-size 16px
    padding-left 7px
    color #fff
    &:after
      content ''
      display inline-block
      position absolute
      left 0px
      top 5px
      width 2px
      height 14px
      background #F89407
      z-index 11
  .add-btn
    margin-left auto
    color #F89407
    font-size 14px
    cursor pointer
    &:hover
      color #DB8307
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 286px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
.brand-zero
  width 100%
  height 175px
  background-color #1E1E23
  border-radius 2px
  text-align center
  display flex
  align-items center
  justify-content center
  img
    width 137px
    height 99px
  p
    margin-top 8px
    color #808191
    font-size 14px
    font-weight 500
.setting-wrap
  height 100%
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
  .content
    padding 24px
    .content-info
      background-color #1e1e23
      padding 24px 24px 1px 24px
      .info-item
        display flex
        justify-content center
        margin-bottom 24px
        .name
          display flex
          color #B2B3BD
          font-size 14px
          height 36px
          align-items center
          span
            color #EE2D41
            margin-right 5px
            margin-left 2px
        .sub-btn
          height 36px
          width 36px
          background-color #373A43
          text-align center
          line-height 36px
          border-radius 2px
          cursor pointer
          .el-icon-minus
            color #fff !important
</style>
