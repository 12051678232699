<template>
  <div class="add-edit-wrap">
    <div class="header">
      <h3>添加/编辑节点</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="add-contnet">
      <div class="header-title">
        <div class="info-title">
          <div>节点设置</div>
        </div>
      </div>
      <div class="content-info">
        <div class="setting-quota">
          <div class="quota-item">
            <div class="item-info">
              <div class="info-name">管控指标<span>*</span></div>
              <div class="height36-input">
                <el-input
                  size='small'
                  v-model="label.value.label"
                  placeholder="请输入内容"
                  disabled
                ></el-input>
              </div>
              <div class="name-abs">{{label.label}}{{'/' + subproperties}}</div>
            </div>
          </div>
          <div class="quota-item">
            <div class="item-info mlr-24">
              <div class="info-name">目标值<span>*</span></div>
              <div class="height36-input">
                <el-input-number
                  size='small'
                  type="number"
                  maxlength='1000'
                  v-model="target"
                  placeholder="请输入内容"
                  :controls="false"
                  :precision="['签约品牌数量', '各级次品牌签约量', '各进度品牌跟进量'].includes(label.value.label) ? 0 : 2"
                  :min="0"
                  onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                  @input="inputTarget"
                ></el-input-number>
              </div>
              <span
                class="symbol"
                v-if="label.value.label !== '签约品牌数量' &&
                label.value.label !== '各级次品牌签约量' &&
                label.value.label !== '各进度品牌跟进量'
              "
              >%</span>
            </div>
          </div>
        </div>
        <div class="setting-quota">
          <div class="quota-item">
            <div class="item-info">
              <div class="info-name">指标时效<span>*</span></div>
              <div class="height36-input">
                <el-date-picker
                  popper-class="black-el-date-picker"
                  v-model="timeArr"
                  type="monthrange"
                  range-separator="~"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  @change="changeTime"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="quota-item">
            <div class="item-info mlr-24">
              <div class="info-name">负责人<span></span></div>
              <div class="height36-input">
                <el-select
                  popper-class="w-block-select-down yellow-select-panel"
                  v-model="userId"
                  size='small'
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in userList"
                    :key="item.userid"
                    :label="item.userName"
                    :value="item.userid"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        :class="[isDisabled ? 'sure' : 'isDisabled']"
        @click="sure()"
      >保存</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
export default {
  data () {
    return {
      target: '',
      userId: '',
      userList: [],
      timeArr: [],
      isDisabled: false
    }
  },
  props: {
    label: {
      type: Object
    },
    labelItem: {
      type: Object
    },
    projectId: {
      type: Number
    },
    subproperties: {
      type: String
    }
  },
  // watch: {
  //   target (newVal, oldVal) {
  //     console.log(newVal, oldVal)
  //   }
  // },
  mounted () {
    this.target = ''
    this.userId = ''
    this.timeArr = []
    // console.log(this.label)
    // console.log(this.labelItem)
    if (this.labelItem.id > 0) {
      this.getTargetLog()
    }
    this.getUserFunc()
  },
  methods: {
    inputTarget () {
      // console.log(this.timeArr)
      // console.log(this.target)
      if (this.target && this.timeArr.length) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    changeTime () {
      if (this.target && this.timeArr) {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    getTargetLog () {
      this.axios.post(api.getProgressControlTargetLog,
        { id: this.labelItem.id })
        .then((res) => {
          this.target = res.data.data.target || ''
          this.timeArr.push(res.data.data.startTime)
          this.timeArr.push(res.data.data.finishTime)
          this.userId = res.data.data.userId || ''
          if (this.target && this.timeArr.length) {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        })
    },
    getUserFunc () {
      this.axios.post(api.getUserListByProjectId,
        { projectId: this.projectId })
        .then((res) => {
          // console.log(res.data.data)
          this.userList = res.data.data
        })
    },
    sure () {
      // if (!this.isDisabled) {
      //   return false
      // }
      if (!this.target) {
        this.$message({
          type: 'warning',
          message: '请输入目标值！'
        })
        return false
      }
      if (!this.timeArr || this.timeArr.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择指标时效！'
        })
        return false
      }
      if (this.target >= 1000) {
        this.$message({
          type: 'warning',
          message: '目标值不能超过1000,请重新输入！'
        })
        return false
      }
      const data = {}
      data.controlProgressType = this.label.dimensionID
      data.controlProgressTarget = this.label.value.targetID
      if (this.labelItem.id > 0) {
        data.id = this.labelItem.id
      }
      data.projectId = this.projectId
      data.target = this.target
      const startTime = dayjs(this.timeArr[0]).format('YYYY-MM-DD')
      const finishTime = dayjs(this.timeArr[1]).format('YYYY-MM-DD')
      data.startTime = startTime
      data.finishTime = finishTime
      data.subproperties = this.subproperties
      if (this.userId) {
        data.userId = this.userId
        const obj = this.userList.find(item => {
          return item.userid === this.userId
        })
        data.userName = obj.userName
      }
      this.axios.post(api.saveProgressControlTargetLog,
        data)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            this.$emit('editSuccess')
          }
        })
    },
    close () {
      this.$emit('closeAddEdit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.header-title
  display flex
  margin-bottom 16px
  .info-title
    position relative
    font-size 16px
    padding-left 7px
    color #fff
    &:after
      content ''
      display inline-block
      position absolute
      left 0px
      top 5px
      width 2px
      height 14px
      background #F89407
      z-index 11
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 286px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
      color rgba(255, 255, 255, 0.5)
.add-edit-wrap
  height 100%
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
  .add-contnet
    padding 24px
    .content-info
      background-color #1e1e23
      padding 24px 24px 1px 24px
      .setting-quota
        display flex
        justify-content center
        margin-bottom 34px
        .quota-item
          .item-info
            display flex
            height 36px
            align-items center
            color #fff
            font-size 14px
            position relative
            .info-name
              width 100px
              text-align right
            span
              color #EE2D41
              margin-right 5px
              margin-left 2px
            .symbol
              position absolute
              right 10px
              top 10px
              color #fff
              font-size 14px
            .name-abs
              position absolute
              color #fff
              font-size 14px
              right 0
              bottom -25px
</style>
