<template>
  <div
    class="plan-management"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div v-if="hasAuth">
      <div class="header">
        <h3 class="w-title-gradients">招商进度管控</h3>
        <div
          class="setting"
          @click="settingClick()"
        >
          <span class="el-icon-setting"></span>
          管控指标设置
        </div>
        <div
          class="dots-wrap"
          v-if="list && list.length > 0"
        >
          <div class="dots-tips">
            <div class="green"><span></span>达标</div>
            <div class="red"><span></span>未达标</div>
          </div>
        </div>
      </div>
      <div
        class="plan-content"
        @mouseleave="hideBox"
      >
        <div class="table-header">
          <div class="header-left">管控指标</div>
          <div class="header-right">
            <div class="year-content">
              <div
                class="year-prev"
                @click="editYear('sub')"
              > <span class="el-icon-arrow-left"></span> </div>
              <div class="year">{{year}}</div>
              <div
                class="year-next"
                @click="editYear('add')"
              > <span class="el-icon-arrow-right"></span> </div>
            </div>
            <div class="month-content">
              <div
                class="month-item"
                v-for="(item, index) in monthList"
                :key="index"
              ><span :class="[activeYear === year && month + '月' === item ? 'active' : '']">{{item}}</span></div>
            </div>
          </div>
        </div>
        <div class="table-content">
          <div
            class="content-item"
            v-for="(item, index1) in list"
            :key="index1"
          >
            <div
              class="item-list"
              @click="openClick(item)"
            >
              <div class="item-name">{{item.label}}</div>
              <div class="item-icon">
                <span
                  @click.stop="openClick(item)"
                  :class="[item.isOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
                ></span>
              </div>
            </div>
            <div
              class="progress-content"
              v-if="item.isOpen"
            >
              <div
                class="progress-item"
                v-for="(list, index2) in item.value.value"
                :key="index2"
              >
                <div class="progress-name">
                  <div class="name">
                    {{list.label}}
                  </div>
                </div>
                <div class="progress-data">
                  <div
                    class="progress-number"
                    @mouseenter="mouseOverDelete(index1, index2, index3, option.id, item)"
                    v-for="(option, index3) in list.value"
                    :key="index3"
                    :id="'block'+index1+index2+index3"
                    @click="addEditPlanClick(item, option, list.label)"
                  >
                    <span
                      v-if="option.id > 0"
                      :style="{'background': item.color, 'borderColor': item.color}"
                    >
                      <i
                        class="number"
                        v-if="option.target >= 0 && option.value === -1"
                      >{{item.value.label === '签约品牌数量' || item.value.label === '各级次品牌签约量' || item.value.label === '各进度品牌跟进量' ? option.target : option.target + '%'}}</i>
                      <a
                        class="delete-p"
                        @click.stop="deletePlanLine(option)"
                        v-if="option.target >= 0 && idx1 === index1 && idx2 === index2"
                      ></a>
                      <i
                        class="number"
                        v-if="option.value !== -1"
                      >{{item.value.label === '签约品牌数量' || item.value.label === '各级次品牌签约量' || item.value.label === '各进度品牌跟进量' ? option.value : option.value + '%'}}
                      </i>
                      <em
                        class="red"
                        v-if="option.isComplete === 2"
                      ></em>
                      <em
                        class="green"
                        v-if="option.isComplete === 1"
                      ></em>
                    </span>
                    <em
                      class="zero-em"
                      v-if="option.target >= 0 || option.value >= 0"
                    >
                      <i
                        class="number"
                        v-if="option.value !== -1"
                      >{{item.value.label === '签约品牌数量' || item.value.label === '各级次品牌签约量' || item.value.label === '各进度品牌跟进量' ? option.value : option.value + '%'}}
                      </i>
                      <a
                        class="delete-p"
                        @click.stop="deletePlanLine(option)"
                        v-if="option.target >= 0 && idx1 === index1 && idx2 === index2"
                      ></a>
                    </em>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="suspension-wrap"
            @mousewheel="hideBox"
            @mouseleave="hideBox"
            :class="{
              'show-box': showTips,
              'hide-box': !showTips
            }"
            :style="{
              top: fixedTop + 'px',
              left: fixedLeft + 'px'
            }"
          >
            <div class="province-name">{{subpropertiesName}}</div>
            <div class="tip-text"> <span class="span1">指标时效：</span> <span class="span2">{{targetLogData.startTime}}~{{targetLogData.finishTime}}</span> </div>
            <div class="tip-text"> <span class="span1">目标值：</span> <span class="span2">{{targetLogData.target}}{{labelName === '签约品牌数量' || labelName === '各级次品牌签约量' || labelName === '各进度品牌跟进量' ? '' : '%'}}</span></div>
            <div class="tip-text"> <span class="span1">负责人：</span> <span class="span2">{{targetLogData.userName || '-'}}</span> </div>
          </div>
        </div>
        <div
          class="no-data fs-14"
          v-if="list && list.length===0">
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>当前项目未配置管控指标</p>
            <span
              class="button-basic button-primary mt10"
              @click="settingClick()"
            >去添加</span>
        </div>
        <!-- <div
          class="table-no-data"
          v-if="list && list.length === 0"
        ></div> -->
      </div>
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="settingSwitch"
        direction="rtl"
      >
        <div v-if="settingSwitch">
          <setting
            @closeSetting='closeSetting'
            @editSuccess='editSuccess'
            :projectId='projectId'
          ></setting>
        </div>
      </el-drawer>
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="addEditSwitch"
        direction="rtl"
      >
        <div v-if="addEditSwitch">
          <addEditPlan
            @closeAddEdit='closeAddEdit'
            @editSuccess="editSuccess"
            :label="label"
            :labelItem="labelItem"
            :subproperties="subproperties"
            :projectId="projectId"
          ></addEditPlan>
        </div>
      </el-drawer>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from '@/api'
import setting from '@/views/workBench/planManagement/components/setting.vue'
import addEditPlan from '@/views/workBench/planManagement/components/addEditPlan.vue'
import { powerAuth } from '@/utils'
export default {
  data () {
    return {
      loading: false,
      settingSwitch: false,
      addEditSwitch: false,
      year: 0,
      monthList: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      list: [],
      label: {},
      labelItem: {},
      subproperties: '',
      month: 0,
      activeYear: 0,
      hasAuth: powerAuth(214),
      showDel: false,
      idx1: null,
      idx2: null,
      showTips: false,
      fixedTop: 0,
      fixedLeft: 0,
      subpropertiesName: '',
      targetLogData: {},
      labelName: ''
    }
  },
  props: {
    projectId: {
      type: Number
    }
  },
  watch: {
    projectId (n) {
      this.loading = true
      this.year = dayjs().year()
      this.activeYear = dayjs().year()
      this.month = dayjs().month() + 1
      this.getTableData()
    }
  },
  components: {
    setting,
    addEditPlan
  },
  mounted () {
    this.loading = true
    this.year = dayjs().year()
    this.activeYear = dayjs().year()
    this.month = dayjs().month() + 1
    if (powerAuth(214)) {
      this.getTableData()
    } else {
      this.loading = false
    }
  },
  methods: {
    hideBox () {
      if (this.showTips) {
        this.idx1 = null
        this.idx2 = null
        this.showTips = false
      }
    },
    mouseOverDelete (idx1, idx2, idx3, id, item) {
      this.subpropertiesName = item.value.label
      this.labelName = item.value.label
      this.idx1 = idx1
      this.idx2 = idx2
      if (id && id > 0) {
        this.getSuspension(id, 'block' + idx1 + idx2 + idx3)
      } else {
        this.idx1 = null
        this.idx2 = null
        this.showTips = false
      }
    },
    getSuspension (id, idValue) {
      this.axios.post(api.getProgressControlTargetLog,
        { id: id })
        .then((res) => {
          const blockObj = document.getElementById(idValue).getBoundingClientRect()
          this.targetLogData = res.data.data
          this.targetLogData.startTime = dayjs(this.targetLogData.startTime).format('YYYY/MM')
          this.targetLogData.finishTime = dayjs(this.targetLogData.finishTime).format('YYYY/MM')
          this.fixedTop = blockObj.y - 150
          this.fixedLeft = blockObj.x - 100
          this.showTips = true
        })
    },
    getTableData () {
      this.axios.post(api.berthTaskProgressControl,
        { projectId: this.projectId, year: this.year })
        .then((res) => {
          this.list = res.data.data
          this.loading = false
        })
    },
    editYear (type) {
      if (type === 'sub') {
        this.year = this.year - 1
      } else {
        this.year = this.year + 1
      }
      this.getTableData()
    },
    openClick (sel) {
      this.list = this.list.map(item => {
        if (sel.label === item.label) {
          item.isOpen = !item.isOpen
        }
        return item
      })
    },
    closeSetting () {
      this.settingSwitch = false
    },
    closeAddEdit () {
      this.addEditSwitch = false
    },
    addEditPlanClick (name, item, subproperties) {
      if (!powerAuth(214, 'addeditnode')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.label = name
      this.labelItem = item
      this.subproperties = subproperties
      this.addEditSwitch = true
    },
    settingClick () {
      if (!powerAuth(214, 'settarget')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.settingSwitch = true
    },
    editSuccess () {
      this.addEditSwitch = false
      this.settingSwitch = false
      this.getTableData()
    },
    deletePlanLine (item) {
      this.axios.post(api.deleteProgressControlTargetLog,
        { id: item.id })
        .then((res) => {
          if (res.data.code === 0) {
            this.getTableData()
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.plan-management
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  background-color #272930
  padding 0 24px 24px 24px
  .header
    position relative
    height 55px
    display flex
    align-items center
    h3
      position absolute
      left -24px
      top 0
    .setting
      position absolute
      right 0px
      top 10px
      color #fff
      font-size 16px
      cursor pointer
      &:hover
        color #F89407
.plan-content
  border 1px solid #373A43
  .table-header
    height 120px
    display flex
    .header-left
      width 191px
      line-height 120px
      border-right 1px solid #373A43
      font-size 20px
      font-weight bold
      color #fff
      margin-left 24px
    .header-right
      width 100%
      .year-content
        height 60px
        display flex
        align-items center
        justify-content center
        border-bottom 1px solid #373A43
        .year-prev, .year-next
          &:hover
            background-color #373A43
            border-color #373A43
        .year
          width 158px
          color #fff
          text-align center
          font-weight bold
        .el-icon-arrow-left, .el-icon-arrow-right
          border 1px solid #373A43
          color #fff
          width 26px
          height 26px
          line-height 26px
          text-align center
          cursor pointer
          border-radius 2px
      .month-content
        display flex
        width 100%
        height 60px
        align-items center
        .month-item
          width 100%
          text-align center
          color #fff
          font-size 12px
          font-weight 400
          span.active
            display inline-block
            width 86px
            height 24px
            background-color #F89407
            line-height 24px
            border-radius 2px
            text-align center
  .table-content
    border-top 1px solid #373A43
    .item-list
      display flex
      height 48px
      align-items center
      background-color #272930
      border-bottom 1px solid #1e1e23
      cursor pointer
      &:hover
        background-color #33343A
        .item-name
          color #F89407
        .el-icon-arrow-up, .el-icon-arrow-down
          color #F89407
      .item-name
        color #B2B3BD
        font-size 12px
        width 191px
        height 100%
        line-height 48px
        padding-left 24px
      .item-icon
        margin-left auto
        margin-right 50px
        color #fff
        cursor pointer
    .progress-content
      background-color #1E1E23
      .progress-item
        display flex
        height 62px
        align-items center
        // &:first-child
        // height 70px
        // .progress-name
        // height 70px
        // .name
        // line-height 52px
        // padding-top 14px
        // &:last-child
        // .progress-name
        // padding-bottom 14px
        .progress-name
          width 191px
          margin-left 24px
          border-right 1px solid #373A43
          height 100%
          font-size 12px
          color #808191
          display flex
          height 62px
          align-items center
        .progress-data
          display flex
          width 100%
          height 42px
          align-items center
          .progress-number
            width 100%
            text-align center
            border-right 1px solid #373A43
            height 62px
            position relative
            overflow hidden
            .zero-em
              display inline-block
              background-color rgba(255, 255, 255, 0.1)
              width 100%
              height 32px
              position absolute
              top 15px
              left 0
              z-index 1
              i
                position absolute
                width calc(100% - 2px)
                height 26px
                left 1px
                top 3px
                color #fff
                font-size 12px
                text-align center
                line-height 26px
              .delete-p
                width 16px
                height 16px
                position absolute
                right 0px
                top 0px
                cursor pointer
                // box-shadow 2px 4px 6px rgba(0, 0, 0, 0.2)
                z-index 1111
                cursor pointer
                background url('~@/assets/images/close-black.png') no-repeat
                background-size 100% 100%
            span
              display inline-block
              background-color #F89407
              width 100%
              height 32px
              position absolute
              top 15px
              left 0
              z-index 1
              border-right 1px solid #F89407
              i
                position absolute
                width calc(100% - 2px)
                height 26px
                left 1px
                top 3px
                background-color rgba(0, 0, 0, 0.1)
                color #fff
                font-size 12px
                text-align center
                line-height 26px
              .delete-p
                width 16px
                height 16px
                position absolute
                right 0px
                top 0px
                cursor pointer
                // box-shadow 2px 4px 6px rgba(0, 0, 0, 0.2)
                z-index 1111
                cursor pointer
                background url('~@/assets/images/close-black.png') no-repeat
                background-size 100% 100%
              .red
                display inline-block
                width 8px
                height 8px
                border-radius 50%
                background-color #FF0000
                position absolute
                right 18px
                top 3px
              .green
                display inline-block
                width 8px
                height 8px
                border-radius 50%
                background-color #00E97A
                position absolute
                right 18px
                top 3px
            &:first-child
              span
                border-radius 2px 0 0 2px
            &:last-child
              span
                border-radius 0 2px 2px 0
.dots-wrap
  position absolute
  right 45px
  top 52px
  width 125px
  margin-top 24px
  .dots-tips
    display flex
    justify-content center
    .green
      color #808191
      font-size 12px
      span
        display inline-block
        width 8px
        height 8px
        background-color #00E97A
        border-radius 50%
        margin-right 5px
    .red
      color #808191
      font-size 12px
      margin-left 32px
      span
        display inline-block
        width 8px
        height 8px
        background-color #FF0000
        border-radius 50%
        margin-right 5px
.table-no-data
  width 100%
  height 500px
  background-color #272930
.show-box
  visibility visible
  opacity 1
.hide-box
  visibility hidden
  opacity 0
.suspension-wrap
  position fixed
  z-index 111
  background-color rgba(0, 0, 0, 0.8)
  transition all .2s ease-in-out
  width 310px
  height 150px
  padding 16px
  border-radius 5px
  .province-name
    color #fff
    font-size 14px
    margin-bottom 8px
  .tip-text
    color #fff
    font-size 14px
    line-height 32px
    .span1
      display inline-block
      width 38%
    .span2
      display inline-block
      width 60%
      text-align right
</style>
